<template>
  <div class="container">
    <Sidebar v-if="user.first_name" />
    <div class="container-inner">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Sidebar from "@/components/Sidebar"
export default {
  name: "MyTempo",
  components: {
    Sidebar
  },
  computed: {
    ...mapState(["user", "token"]),
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {  
      if (this.user.id && this.token != '') {
        this.setCurrentUser(this.user);
      } else {
        this.getCurrentUser();
      }
    },
    getCurrentUser() {
      this.$store.dispatch('getCurrentUser');
    },
    setCurrentUser(user) {
      if (this.user.id) {
        this.$store.dispatch('setCurrentUser', user );
      } else {
        this.$store.dispatch('setCurrentUser', {});
      }
    }
  }
}
</script>

<style>
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.container {
  display: flex;
  min-height: 100vh;
}
.container-inner {
  padding: 30px;
  width: calc(100vw - 240px);
}

.el-form-item__content {
  display: flex;
  flex-wrap: wrap;
}
.el-dialog__body {
  overflow-y: scroll;
  max-height: 540px;
}
.el-overlay {
  z-index: 1 !important;
}
.el-overlay-dialog .el-dialog {
  margin-top: 10vh;
}
.el-pagination .el-pagination__total {
  margin-left: 20px;
}
.mt-be-errors {
  color: red
}
</style>
