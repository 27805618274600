import axios from 'axios'
import { getLocalStorageToken } from '@/services'

export default () => {
  let config = {
    baseURL: 'https://mytempo.club/api/'
  }
  let instance = axios.create(config)
  const token = getLocalStorageToken()
  if (token) {
    instance.defaults.headers.common['Authorization'] = "Bearer " + token
  }
  return instance
}
