import { createStore } from 'vuex'
import {
  //user imports
  setLocalStorageToken,
  setLocalStorageUser,

  getLocalStorageToken,
  getLocalStorageUser,
  localStorageLogout,

  login,
  register,
  resetPassword,
  //destination imports
  // fetchDestinations
} from '@/services'

export default createStore({
  state: {
    user: {},
    token: '',
  },
  mutations: {
    SET_CURRENT_USER(state, user) {
      state.user = user
      setLocalStorageUser(user)
    },
    SET_CURRENT_TOKEN(state, token) {
      state.token = token
      setLocalStorageToken(token)
    },
    SET_USER_FROM_LS(state, user) {
      state.user = user
    },
    SET_TOKEN_FROM_LS(state, token) {
      state.token = token
    },
    LOGOUT_USER(state) {
      console.log("LOGOUT")
      state.user = null
      state.token = null
      localStorageLogout()
    }
  },
  actions: {
    setCurrentUser({ commit }, user) {
      commit('SET_CURRENT_USER', user)
      return
    },
    async getCurrentUser({ commit }) {
      let user = await getLocalStorageUser()
      let token = await getLocalStorageToken()
      commit('SET_USER_FROM_LS', user)
      commit('SET_TOKEN_FROM_LS', token)
      return user
    },
    async getCurrentToken() {
      return await getLocalStorageToken()
    },
    logoutUser({ commit }) {
      commit('LOGOUT_USER')
    },
    async loginUser({ commit }, loginInfo) {
      try {
        let response = await login(loginInfo)
        if (response.status == 200) {
          let user = response.data.data.user
          let token = response.data.data.token
          commit('SET_CURRENT_USER', user)
          commit('SET_CURRENT_TOKEN', token)
          return { status: 'success' }
        } else {
          return { error: "Not authenticated. Try again..." }
        }
      } catch (error) {
        return { error: "Not authenticated. Try again..." }
      }
    },
    async registerUser({ commit }, loginInfo) {
      console.log('commit', commit)
      try {
        let response = await register(loginInfo)
        if (response.status == 200) {
          return { status: 'success' }
        } else {
          return { error: "Not authenticated. Try again..." }
        }
      } catch (error) {
        return { error: "Not authenticated. Try again..." }
      }
    },
    async resetPassword({ commit }, data) {
      console.log("reset pass data", commit, data)
      try {
        let response = await resetPassword(data)
        if (response.status == 200) {
          return true
        }
      } catch (error) {
        console.log("error", error)
        return false
      }
    },
  },
  modules: {
  }
})
