import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Login.vue')
  },
  {
    path: '/exercises',
    name: 'Exercises',
    component: () => import(/* webpackChunkName: "exercises" */ '@/views/Exercises.vue')
  },
  {
    path: '/workouts',
    name: 'Workouts',
    component: () => import(/* webpackChunkName: "workouts" */ '@/views/Workouts.vue')
  },
  {
    path: '/workout-plans',
    name: 'Workout plans',
    component: () => import(/* webpackChunkName: "workout-plans" */ '@/views/WorkoutPlans.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue')
  },
  {
    path: '/contact-messages',
    name: 'ContactMessages',
    component: () => import(/* webpackChunkName: "ContactMessages" */ '@/views/ContactMessages.vue')
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: () => import(/* webpackChunkName: "ContactMessages" */ '@/views/Blogs.vue')
  },
  {
    path: '/achievements',
    name: 'Achievements',
    component: () => import(/* webpackChunkName: "Achievements" */ '@/views/Achievements.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((from, to, next) => {
  let token = localStorage.getItem('token')
  if (token) {
    let tokenData = token.split('.')[1]
    let decodedTokenJsonData = window.atob(tokenData)
    let expiry_date = JSON.parse(decodedTokenJsonData).exp * 1000
    let now = new Date().getTime()
    if (expiry_date < now) {
      store.dispatch("logoutUser")
    }
  } else if (from.path == "/login") {
    next();
  } else {
    store.dispatch("logoutUser")
  }
  next();
});

export default router
