import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import VueApexCharts from 'vue-apexcharts'
import store from './store'
import router from './router'
import Editor from '@tinymce/tinymce-vue'

const app = createApp(App)
app.use(router)
// app.use(require('vue-moment'))
// app.use(VueApexCharts)
// Vue.component('apexchart', VueApexCharts)
app.use(store)
app.use(ElementPlus)
app.component("editor", Editor)
app.mount('#app')
