export function parseMuscleValue(value) {
  if (value > 0) {
    if (value / 50 > 0.6) {
      return 0.6
    } else {
      return value / 50
    }
  } else {
    return 0
  }
}

export function parseWorkoutDuration(duration) {
  let durationMinutes = String(parseInt(duration/60))
  while (durationMinutes.length < 2) {
    durationMinutes = "0" + durationMinutes
  }
  let durationSeconds = String(duration % 60)
  while (durationSeconds.length < 2) {
    durationSeconds = "0" + durationSeconds
  }
  return durationMinutes + ":" + durationSeconds
}

export function getImage(data) {
  if (data) {
    if (data.indexOf("http") === 0) {
      return data
    }
    if (data.includes("data:image")) {
      return data
    }
    else {
      return 'https://mytempo.club/api/read-file/' + data
    }
  }
}
